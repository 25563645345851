.arrow-right {
  opacity: .5;
  background-image: url("arrow-down.e8d961e2.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

html {
  width: 100%;
  height: 100%;
}

body {
  min-height: 100vh;
}

.LANDING-PAGE {
  background-color: #0000;
  background-image: url("background-2x.33b19d7a.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.LANDING-PAGE .div {
  width: 100%;
  height: 100vh;
  position: relative;
}

.LANDING-PAGE .img-copyright {
  object-fit: cover;
  width: 194px;
  height: 23px;
  position: absolute;
  bottom: 60px;
  right: 70px;
}

.LANDING-PAGE .img-logo-gama {
  object-fit: cover;
  height: 44px;
  position: absolute;
  top: 40px;
  left: 60px;
}

.LANDING-PAGE .content {
  flex-direction: column;
  align-items: center;
  gap: 45px;
  display: inline-flex;
}

.LANDING-PAGE .img-logo-gama-l {
  object-fit: cover;
  width: 120px;
  height: 120px;
  position: relative;
}

.LANDING-PAGE .text-main {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  text-fill-color: transparent;
  background: linear-gradient(#97f4bc 0%, #51c7fa 100%) text;
  font-family: Danzza-Regular, Helvetica;
  font-size: 74px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  -webkit-background-clip: text !important;
}

.LANDING-PAGE .text-secondary {
  color: #d3d3d3;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Danzza-Regular, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.LANDING-PAGE .frame {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
}

.LANDING-PAGE .countdown {
  background-color: #fff;
  border-radius: 28px;
  width: 297.57px;
  height: 56.49px;
  position: relative;
}

.LANDING-PAGE .btn-email {
  background: linear-gradient(#7b7ce1 0%, #b1b1df 100%);
  border-radius: 22.5px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 6px;
  left: 245px;
}

.LANDING-PAGE .icon-email {
  width: 22px;
  height: 15px;
  position: absolute;
  top: 15px;
  left: 11px;
}

.LANDING-PAGE .text-wrapper {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Danzza-Medium, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 30px;
}

.LANDING-PAGE .input-email {
  background-color: #7d7de1;
  border-radius: 28px;
  width: 297.57px;
  height: 56.49px;
  position: relative;
}

.LANDING-PAGE .btn-envoyer {
  background-color: #7d7de1;
  border: 2px solid #fff;
  border-radius: 22.5px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 6px;
  left: 245px;
}

.LANDING-PAGE .arrow-right-instance {
  position: relative !important;
  top: 8px !important;
  left: 8px !important;
}

.LANDING-PAGE .line {
  object-fit: cover;
  width: 190px;
  height: 1px;
  position: absolute;
  top: 40px;
  left: 30px;
}

::placeholder {
  color: #fff;
  opacity: 1;
}

.LANDING-PAGE .text-wrapper-2 input {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  outline: none;
  font-family: Danzza-Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 10px;
}

.LANDING-PAGE .feedback-valid {
  background-color: #7d7de1;
  border-radius: 22.5px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 883px;
  left: 938px;
}

.LANDING-PAGE .add-remove-cancel-ok {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
}

.LANDING-PAGE .text-wrapper-3 {
  color: gray;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Danzza-Medium, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 25px;
  left: 50%;
}

.LANDING-PAGE .text-wrapper-4 {
  color: gray;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Danzza-Medium, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: 25px;
  left: 50%;
}

.LANDING-PAGE .text-wrapper-5 {
  color: gray;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Danzza-Medium, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 60px;
}

.LANDING-PAGE .text-wrapper-6 {
  color: gray;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Danzza-Medium, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 50%;
  left: 60px;
}

@media screen and (width <= 900px) {
  .text-wrapper-6, .text-wrapper-4, .text-wrapper-5, .text-wrapper-3, .img-logo-gama, .img-copyright {
    display: none;
  }
}
/*# sourceMappingURL=index.14d9d7c1.css.map */
