html {
  height: 100%;
  width: 100%;
}
body {
  min-height: 100vh;
}

.LANDING-PAGE {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
  background-image: url(../../../static/img/background-2x.png);
  background-position: 50% 50%;
  background-size: cover;
}

.LANDING-PAGE .div {
  height: 100vh;
  position: relative;
  width: 100%;
}

.LANDING-PAGE .img-copyright {
  height: 23px;
  right: 70px;
  object-fit: cover;
  position: absolute;
  bottom: 60px;
  width: 194px;
}

.LANDING-PAGE .img-logo-gama {
  height: 44px;
  left: 60px;
  object-fit: cover;
  position: absolute;
  top: 40px;
}

.LANDING-PAGE .content {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 45px;
}

.LANDING-PAGE .img-logo-gama-l {
  height: 120px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.LANDING-PAGE .text-main {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(151.25, 244, 187.79) 0%,
    rgb(81.33, 199, 250.17) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Danzza-Regular", Helvetica;
  font-size: 74px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.LANDING-PAGE .text-secondary {
  color: #d3d3d3;
  font-family: "Danzza-Regular", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.LANDING-PAGE .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.LANDING-PAGE .countdown {
  background-color: #ffffff;
  border-radius: 28px;
  height: 56.49px;
  position: relative;
  width: 297.57px;
}

.LANDING-PAGE .btn-email {
  background: linear-gradient(
    180deg,
    rgb(123, 124, 225) 0%,
    rgb(177, 177, 223) 100%
  );
  border-radius: 22.5px;
  height: 45px;
  left: 245px;
  position: absolute;
  top: 6px;
  width: 45px;
}

.LANDING-PAGE .icon-email {
  height: 15px;
  left: 11px;
  position: absolute;
  top: 15px;
  width: 22px;
}

.LANDING-PAGE .text-wrapper {
  color: #000000;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.LANDING-PAGE .input-email {
  background-color: #7d7de1;
  border-radius: 28px;
  height: 56.49px;
  position: relative;
  width: 297.57px;
}

.LANDING-PAGE .btn-envoyer {
  background-color: #7d7de1;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 22.5px;
  height: 45px;
  left: 245px;
  position: absolute;
  top: 6px;
  width: 45px;
}

.LANDING-PAGE .arrow-right-instance {
  left: 8px !important;
  position: relative !important;
  top: 8px !important;
}

.LANDING-PAGE .line {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 190px;
}

::placeholder {
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.LANDING-PAGE .text-wrapper-2 input {
  color: #ffffff;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 15px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  outline: none;
}

.LANDING-PAGE .feedback-valid {
  background-color: #7d7de1;
  border-radius: 22.5px;
  height: 45px;
  left: 938px;
  position: absolute;
  top: 883px;
  width: 45px;
}

.LANDING-PAGE .add-remove-cancel-ok {
  height: 24px !important;
  left: 10px !important;
  position: absolute !important;
  top: 10px !important;
  width: 24px !important;
}

.LANDING-PAGE .text-wrapper-3 {
  color: #808080;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 30px;
  font-weight: 500;
  left: 50%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
}

.LANDING-PAGE .text-wrapper-4 {
  color: #808080;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 30px;
  font-weight: 500;
  left: 50%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  bottom: 25px;
  white-space: nowrap;
}

.LANDING-PAGE .text-wrapper-5 {
  color: #808080;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 30px;
  font-weight: 500;
  right: 60px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50%;
  white-space: nowrap;
}

.LANDING-PAGE .text-wrapper-6 {
  color: #808080;
  font-family: "Danzza-Medium", Helvetica;
  font-size: 30px;
  font-weight: 500;
  left: 60px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50%;
  white-space: nowrap;
}

@media screen and (max-width: 900px) {
  .text-wrapper-6 {
    display: none;
  }
  .text-wrapper-4 {
    display: none;
  }
  .text-wrapper-5 {
    display: none;
  }
  .text-wrapper-3 {
    display: none;
  }
  .img-logo-gama {
    display: none;
  }
  .img-copyright {
    display: none;
  }
}
